<template>
	<div class="col-12">
		<div class="row" v-for="(item, index) in segments" :key="`segment-${index}`">
			<div class="col-5">
				<recess-multi-select
					:selected-options="item.value"
					:options="segmentsOptions"
					:search-input="true"
					:single-select="true"
					:label-text="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSegments.Label"
					:placeholder="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSegments.Placeholder"
					:error-message="segmentError(index)"
					:class="`qa-segment${index}-segment`"
					@input="(newValue) => onSegmentChange({ segmentIndex: index, segment: newValue })"
					@blur="$v.segments.$each[index].value.$touch()"
				/>
			</div>

			<div class="col-7 d-flex">
				<recess-multi-select
					:selected-options="item.subSegments"
					:options="item.filteredSubSegmentOptions"
					:search-input="true"
					:enable-preview-selected-items="true"
					:label-text="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSubSegments.Label"
					:placeholder="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSubSegments.Placeholder"
					:error-message="subSegmentError(index)"
					:class="`w-100 mr-3 qa-segment${index}-subsegment`"
					@input="(newValue) => onSubSegmentChange({ segmentIndex: index, subSegment: newValue })"
					@blur="$v.segments.$each[index].subSegments.$touch()"
				/>

				<div class="d-flex align-items-center s-delete-icon__input-align">
					<span v-if="segments !== null && segments.length > 0" :class="['c-icon c-icon__bin ', getRemoveSegmentClass(index)]" @click="removeSegmentItem(index)"> </span>
				</div>
			</div>
		</div>
		<!-- add segment button -->
		<div class="row">
			<div class="col-12 d-flex justify-content-end">
				<button-component
					type="button"
					:back-label="PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.AddSegmentsAndSubsegment"
					icon-font="add"
					button-class="m-0 c-button--variant4 qa-add-segment-item-button"
					@click.native.prevent="addSegmentItem()"
				/>
			</div>
		</div>
	</div>
</template> 

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

// TODO: Remove when all course is using patch and not put
import { mapState, mapMutations, mapActions } from 'vuex'

import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'

import { getItems, getItemById, postItem, deleteItem } from '@/../../shared/api/SharedClient'

import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'

import { requiredError, isRequiredErrorMessage, isUniqueErrorMessage, defaultErrorMessage, defaultEditSuccessMessage, removeSegmentError } from '../../../../../../shared/constants/validationMessageHelper'

const ButtonComponent = () => import('@/components/atoms/ButtonComponent')
const renameKeys = (keysMap, obj) =>
	Object.keys(obj).reduce(
		(acc, key) => ({
			...acc,
			...{ [keysMap[key] || key]: obj[key] }
		}),
		{}
	)

export default {
	name: 'SegmentsAndSubsegments',
	components: {
		ButtonComponent
	},
	mixins: [validationMixin],
	props: {
		courseId: {
			type: String,
			default: null
		},
		coursesIds: {
			type: Array,
			default: () => []
		},
		triggerValidation: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			PROVIDER,
			selectedSegments: [],
			segments: [],
			segmentsOptions: [
				{
					displayText: PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SegmentsOptions.InitialOption,
					value: ''
				}
			],
			productSubSegments: []
		}
	},
	computed: {
		// TODO: Remove when all course is using patch and not put
		...mapState('courseModule', ['course']),
		subSegmentsRequired() {
			return this.course.source !== 'Edudex'
		},
		subSegmentLabel() {
			return this.subSegmentsRequired
				? `${PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSubSegments.Label}*`
				: PROVIDER.Portfolio.CourseCreate.Form.SegmentsAndSubsegments.SelectSubSegments.Label
		}
	},
	validations: {
		segments: {
			$each: {
				value: {
					required,
					isUnique(value, segment) {
						const mappedSegment = this.segments.map((e) => {
							return e.value
						})
						const indexOfSegment = mappedSegment.indexOf(segment.value)
						mappedSegment.splice(indexOfSegment, 1)
						if (mappedSegment.length > 0) {
							return mappedSegment.indexOf(segment.value) === -1
						}
						return true
					}
				},

				subSegments: {
					// eslint-disable-next-line func-names
					required: requiredIf(function (segment) {
						return this.subSegmentsRequired && this.hasSegmentValue(segment)
					})
				}
			}
		}
	},
	watch: {
		'$v.$invalid': function watchInvalidField(newValue) {
			this.$bus.emit('trigger-validation-message', !newValue)
		},
		triggerValidation(newValue) {
			if (!newValue) return

			this.$v.$touch()
		}
	},
	async mounted() {
		// gets all segments and subsegments associated to the courseId
		await this.getProductSubSegments()

		// gets subsegments and segments list
		await this.getSegmentsOptions()
		await this.setSubSegmentOptions()

		this.setSelectedSegments()

		this.$nextTick(() => {
			this.$bus.emit('trigger-validation-message', !this.$v.$invalid)
			this.$bus.on('update-segments', this.updateSegments)
			this.$bus.on('update-courses-segments', this.updateCoursesSegments)
			this.$bus.on('create-course-segments', this.createCourseSegments)
		})
	},
	beforeDestroy() {
		this.$bus.off('update-segments')
		this.$bus.off('update-courses-segments')
		this.$bus.off('create-course-segments')
	},
	methods: {
		// TODO: Remove when all course is using patch and not put
		...mapActions('courseModule', ['fetchCourse']),
		...mapMutations('courseModule', ['setSegments']),
		hasSegmentValue(segment) {
			return segment && segment.value
		},
		getRemoveSegmentClass(index) {
			let defaulfClass = 'o-form__button'
			if (index > 0) {
				defaulfClass += ` mb-0 qa-remove-segments-button-${index}`
			} else {
				defaulfClass += ` qa-remove-segments-button-${index}`
			}

			return defaulfClass
		},
		subSegmentError(index) {
			return isRequiredErrorMessage('subsegments', 'Subsegment', this.$v.segments.$each[index].subSegments)
		},
		segmentError(index) {
			if (!this.$v.segments.$each[index].value.$error) return null
			
			if (!this.$v.segments.$each[index].value.required) {
				return requiredError('Segment')
			}

			return isUniqueErrorMessage('Segment', this.$v.segments.$each[index].value, 'isUnique')
		},
		async getProductSubSegments() {
			if (!this.courseId) return
			try {
				const response = await getItems(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.productSubSegments, 1, 99999, null, `productReference eq 'course:${this.courseId}'`, false)
				if (!response) return

				this.productSubSegments = response
			} catch (error) {
				console.error('Something went wrong while retrieving product sub segments list', error)
			}
		},
		async postProductSubSegment(newProductSubSegment) {
			if (newProductSubSegment.length === 0 && !this.course.id) return

			await postItem(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.productSubSegments, newProductSubSegment, false)
				.then(() => {
					const toastNotification = {
						type: 'success',
						message: defaultEditSuccessMessage('Het segment')
					}
					this.$store.dispatch('toastNotificationModule/add', toastNotification, {
						root: true
					})
				})
				.catch((error) => {
					this.getNotificationError(defaultErrorMessage)
					console.error('Something went wrong while posting the sub segment', error)
				})
		},
		async onSegmentChange({ segmentIndex, segment }) {
			const indexOfSegmentOption = this.segmentsOptions.findIndex((x) => x.value === segment)
			const selectedOption = this.segmentsOptions[indexOfSegmentOption]
			this.segments[segmentIndex].value = selectedOption.value
			this.segments[segmentIndex].id = selectedOption.id
			this.segments[segmentIndex].filteredSubSegmentOptions = selectedOption.filteredSubSegmentOptions
			this.segments[segmentIndex].subSegments = []
			this.setSegments({ segments: this.segments })
			this.$v.segments.$each[segmentIndex].value.$touch()
		},
		async onSubSegmentChange({ segmentIndex, subSegment }) {
			this.segments[segmentIndex].subSegments = subSegment

			// TODO: Remove when all course is using patch and not put
			this.setSegments({ segments: this.segments })
		},
		async getSegmentsOptions() {
			try {
				const response = await getItems(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.segments, 1, 99999, '', '', false)
				if (!response) return
				const keysMap = {
					name: 'value',
					description: 'displayText'
				}

				const renamedResult = response.items.map((obj) => renameKeys(keysMap, obj))
				this.segmentsOptions = renamedResult
			} catch (error) {
				console.error('Something went wrong while retrieving segment enum list', error)
			}
		},
		async setSubSegmentOptions() {
			await this.segmentsOptions.reduce(async (acc, segment, segmentIndex) => {
				await this.getSubSegmentOptions(segment, segmentIndex)
				return acc
			}, [])
		},
		async getSubSegmentOptions(segment, segmentIndex) {
			if (segment) {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.segments, segment.id, 'subSegments', false)
				try {
					if (!response) return

					const keysMap = {
						name: 'value',
						description: 'displayText'
					}
					const renamedResult = response.subSegments.map((obj) => renameKeys(keysMap, obj))
					this.segmentsOptions[segmentIndex].filteredSubSegmentOptions = renamedResult
				} catch (error) {
					console.error('Something went wrong while retrieving the subSegments', error)
				}
			}
		},
		async getSelectedSubSegments(courseId) {
			const selectedSubSegments = []
			const productReference = `course:${courseId}`

			this.segments.reduce((accSegments, segment) => {
				const segmentOptionsSelectedValues = segment.filteredSubSegmentOptions.filter((filteredSubSegmentOption) => {
					return segment.subSegments.some((item) => {
						return filteredSubSegmentOption.value === item
					})
				})
				segmentOptionsSelectedValues.forEach((segmentOptionsSelectedItem) => {
					selectedSubSegments.push({
						subSegmentId: segmentOptionsSelectedItem.id,
						productReference
					})
				})

				return accSegments
			}, [])

			return selectedSubSegments
		},

		async createCourseSegments() {
			const segmentsToQueue = await this.getSelectedSubSegments(this.course.id)
			await this.postProductSubSegment(segmentsToQueue)
		},
		// Called on Group Action - Portfolio List
		async updateCoursesSegments() {
			if (!this.coursesIds || this.coursesIds.length === 0) return
			let selectedSubSegmentsAllCourses = []
			await this.coursesIds.reduce(async (acc, courseId) => {
				const productReference = `course:${courseId.courseId}`
				await this.deleteSubSegmentProductsByProductReference(productReference)
				selectedSubSegmentsAllCourses = [...selectedSubSegmentsAllCourses, ...(await this.getSelectedSubSegments(courseId.courseId))]
				return acc
			}, [])

			await this.postProductSubSegment(selectedSubSegmentsAllCourses)

			// TODO: Uncoment this when we stop using put
			// const toastNotification = {
			//     type: 'success',
			//     message: this.coursesIds && this.coursesIds > 1 ? 'De leeraanboden zijn succesvol bijgewerkt' : 'Je wijzigingen zijn succesvol toegepast'
			// }

			// this.$store.dispatch('toastNotificationModule/add', toastNotification, {
			//     root: true
			// })
		},

		async updateSegments() {
			// TODO: Uncoment this when we stop using put
			// this.$v.$touch()
			// if (this.$v.$invalid) return
			// const selectedSubSegments = await this.getSelectedSubSegments(this.courseId)
			// const productSubSegmentsToDelete = []
			// this.productSubSegments.items.forEach((productSubSegmentItem) => {
			//     let deleteSubSegment = true
			//     for(let counter = 0;  counter < selectedSubSegments.length; counter += 1){
			//         if(selectedSubSegments[counter].subSegmentId === productSubSegmentItem.subSegmentId)
			//         {
			//             deleteSubSegment = false
			//             break
			//         }
			//     }
			//     if(deleteSubSegment){
			//         productSubSegmentsToDelete.push(productSubSegmentItem)
			//     }
			// })
			// const productSubSegmentsToCreate = []
			// selectedSubSegments.forEach((selectedSubSegment) => {
			//     let addSubSegment = true
			//     for(let counter = 0;  counter < this.productSubSegments.items.length; counter += 1){
			//         if(this.productSubSegments.items[counter].subSegmentId === selectedSubSegment.subSegmentId)
			//         {
			//             addSubSegment = false
			//             break
			//         }
			//     }
			//     if(addSubSegment){
			//         productSubSegmentsToCreate.push(selectedSubSegment)
			//     }
			// })
			// await productSubSegmentsToDelete.reduce(async(acc,productSubSegmentToDeleteItem)=>{
			//     await this.deleteSubSegmentProductById(productSubSegmentToDeleteItem.id)
			//  return acc
			// }, [])
			// await this.postProductSubSegment(productSubSegmentsToCreate)
			// if(productSubSegmentsToDelete.length>0 || productSubSegmentsToCreate.length>0){
			//     this.getProductSubSegments()
			//     // Show notification after an action is finished
			// const toastNotification = {
			//     type: 'success',
			//     message: this.coursesIds && this.coursesIds > 1 ? 'De leeraanboden zijn succesvol bijgewerkt' : 'Het leeraanbod is succesvol bijgewerkt'
			// }
			// this.$store.dispatch('toastNotificationModule/add', toastNotification, {
			//     root: true
			// })
			//     if (!this.courseId) return
			//     await this.fetchCourse(this.courseId)
			// }
		},
		setSelectedSegments() {
			if (this.productSubSegments && this.productSubSegments.items && this.productSubSegments.items.length > 0) {
				const productSubSegmentCopy = JSON.parse(JSON.stringify(this.productSubSegments))
				this.segmentsOptions.reduce((acc, segment) => {
					// copy the segment option and break the ref
					const segmentCopy = JSON.parse(JSON.stringify(segment))
					segmentCopy.subSegments = []

					let hasSelectedSegment = false

					// we loop through the productSubSegments to check if the segment id matches the segmentid of the option
					// or the subSegmentId matches the subSegments of the segment option
					productSubSegmentCopy.items = productSubSegmentCopy.items.filter((productSubSegment) => {
						// since we know that if you have a segmentId on productSubSegment we don't have any subSegment selected
						if (productSubSegment.segmentId === segment.id) {
							hasSelectedSegment = true
							return false
						}

						if (productSubSegment.subSegmentId !== null) {
							// Check if the productSubSegments belongs to the subSegments options of the segment
							const segmentOptionsSelectedValues = segment.filteredSubSegmentOptions.filter((subSegmentOption) => {
								return productSubSegment.subSegmentId === subSegmentOption.id
							})
							if (segmentOptionsSelectedValues.length > 0) {
								const selectedSubSegments = segmentOptionsSelectedValues.map((result) => {
									return result.value
								})
								segmentCopy.subSegments = [...segmentCopy.subSegments, ...selectedSubSegments]

								hasSelectedSegment = true
								return false
							}
						}

						return true
					})

					if (hasSelectedSegment) {
						this.selectedSegments = [...this.selectedSegments, segmentCopy]
					}

					return acc
				}, [])

				this.segments = JSON.parse(JSON.stringify(this.selectedSegments))
			} else {
				this.addSegmentItem()
			}
		},
		addSegmentItem() {
			const emptySegmentItem = {
				value: null,
				displayText: null,
				subSegments: [],
				filteredSubSegmentOptions: []
			}
			this.$v.$touch()
			this.$emit('manuallyTriggerValidation', false)
			this.segments.push(emptySegmentItem)
		},
		removeSegmentItem(indexToRemove) {
			if (this.segments && this.segments.length === 1) {
				const toastNotification = {
					type: 'error',
					message: removeSegmentError
				}
				this.$store.dispatch('toastNotificationModule/add', toastNotification, {
					root: true
				})
			}
			if (this.segments && this.segments.length > 1) {
				this.segments.splice(indexToRemove, 1)
			}
			// TODO:remove this when the put is deleted.
			this.setSegments({ segments: this.segments })
			this.$bus.emit('trigger-validation-message', !this.$v.$invalid)
		},
		async deleteSubSegmentProductById(subSegmentProductId) {
			if (!subSegmentProductId) {
				return
			}

			await deleteItem(process.env.VUE_APP_PROVIDER_API_URL, `${API_CALL_URL_PATHS.productSubSegments}/${subSegmentProductId}`, false).catch((error) => {
				this.getNotificationError(defaultErrorMessage)
				console.error('Something went wrong while deleting sub segment product item', error)
			})
		},
		async deleteSubSegmentProductsByProductReference(productReference) {
			if (!productReference) {
				return
			}

			await deleteItem(process.env.VUE_APP_PROVIDER_API_URL, `${API_CALL_URL_PATHS.productSubSegments}?productReference=${productReference}`, false).catch((error) => {
				this.getNotificationError(defaultErrorMessage)
				console.error('Something went wrong while deleting sub segment products items', error)
			})
		},
		getNotificationError() {
			const toastNotification = {
				type: 'error',
				message: defaultErrorMessage
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		}
	}
}
</script>
